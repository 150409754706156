import React, { createContext, useState, useCallback, ReactNode } from "react";
import { useTranslation } from "react-i18next";
import { Modal, Spinner } from "reactstrap";

interface ModalProps {
  title?: React.ReactNode;
  message: React.ReactNode;
  submitButtonText?: string;
  cancelButtonText?: string;
  isHiddenClose?: boolean;
  loading?: boolean;
}

interface ModalContextType {
  showModal: (props: ModalProps, onSubmit: () => void) => void;
}

const ModalContext = createContext<ModalContextType | undefined>(undefined);

interface ProviderProps {
  children: ReactNode;
}

export const ModalProvider = ({ children }: ProviderProps) => {
  const { t } = useTranslation();
  const [open, setOpen] = useState(false);
  const [setting, setSetting] = useState({} as ModalProps);
  const [onSubmit, setOnSubmit] = useState<() => void>(() => { });

  const showModal = useCallback((props: ModalProps, onSubmit: () => void) => {
    setSetting(props);
    setOnSubmit(() => onSubmit);
    setOpen(true);
  }, []);

  const handleConfirm = async () => {
    try {
      setSetting((prev) => ({ ...prev, loading: true }));
      await onSubmit(); 
      handleClose(); 
    } catch (error) {
      setSetting((prev) => ({ ...prev, loading: false }));
    } finally {
      setSetting((prev) => ({ ...prev, loading: false }));
    }
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <ModalContext.Provider value={{ showModal }}>
      {children}
      <Modal
        isOpen={open}
        centered={true}
        onClose={handleClose}
        scrollable={false}
        style={{ width: "321px" }}
      >
        <div className="p-3">
          <div
            style={{
              color: "#222",
              fontSize: "18px",
              fontWeight: 700,
              textAlign: "center",
            }}
          >
            {setting.title || t("cms_media.check")}
          </div>
          <div
            style={{ marginTop: "10px", color: "#5F646F", textAlign: "center" }}
          >
            {setting.message}
          </div>
          <div
            style={{
              marginTop: "30px",
              gap: "10px",
              display: "flex",
              justifyContent: "center",
              width: "100%",
            }}
          >
            {!setting.isHiddenClose && (
              <button
                type="button"
                className="btn btn-secondary w-100"
                onClick={handleClose}
              >
                {setting.cancelButtonText || t("cms_media.cancellation")}
              </button>
            )}
            <button
              type="button"
              className="btn btn-primary w-100"
              onClick={handleConfirm}
            >
             {setting.loading && <Spinner size="sm" className='me-2'/>}
              {setting.submitButtonText || t("cms_media.save")}
            </button>
          </div>
        </div>
      </Modal>
    </ModalContext.Provider>
  );
};

export const useModal = () => {
  const context = React.useContext(ModalContext);
  if (!context) {
    throw new Error("useModal must be used within a ModalProvider");
  }
  return context;
};
