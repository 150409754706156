import cmsHubMembershipApi from "api/membershipApi";
import { useFormik } from "formik";
import { catchAsync } from "helpers/general";
import moment from "moment";
import DateTimePickerCustom from "pages/CmsHub/Components/DateTimePicker";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { toast } from "react-toastify";
import {
  FormFeedback,
  Input,
  Modal,
  ModalBody,
  Spinner,
} from "reactstrap";
import * as Yup from "yup";

interface Props {
  open: boolean;
  onClose: () => void;
  refesh: () => void;
}
const ModalRegisterPresscon = ({ open, onClose, refesh }: Props) => {
  const { t } = useTranslation();
  const [loading, setLoading] = useState(false);

  const initialValues = {
    company_name: "",
    domain: "https://",
    domain_start_date: "",
    domain_end_date: "",
    footer_configs: {
      registration_number: "",
      company_registration_number: "",
      chief_editor: "",
      publisher: "",
      youth_protection_officer: "",
      address: "",
      main_phone: "",
    },
  };

  const createPopupSchema = Yup.object().shape({
    company_name: Yup.string().required(t('media.CompanyNameCannotBeEmpty')),
    domain: Yup.string()
      .matches(
        /^(https?:\/\/)?([a-zA-Z0-9-]+\.)+[a-zA-Z]{2,6}(\/[\w\.-]*)*\/?$|^https?:\/{2}$/,
        t('media.URLFormatIsIncorrect')
      )
      .optional()
      .nullable(),
    domain_start_date: Yup.string().optional().nullable(),
    domain_end_date: Yup.string().optional().nullable(),
    footer_configs: Yup.object({
      registration_number: Yup.string().optional().nullable(),
      company_registration_number: Yup.string().optional().nullable(),
      chief_editor: Yup.string().optional().nullable(),
      publisher: Yup.string().optional().nullable(),
      youth_protection_officer: Yup.string().optional().nullable(),
      address: Yup.string().optional().nullable(),
      main_phone: Yup.string().optional().nullable(),
    }),
  });

  const removeEmptyFields = (obj: any): any => {
    const result: any = {};

    for (const key in obj) {
      if (obj[key] !== null && obj[key] !== undefined && obj[key] !== "") {
        if (typeof obj[key] === "object" && !Array.isArray(obj[key])) {
          const nestedObject = removeEmptyFields(obj[key]);
          if (Object.keys(nestedObject).length > 0) {
            result[key] = nestedObject;
          }
        } else if (Array.isArray(obj[key])) {
          const filteredArray = obj[key]
            .map((item: any) =>
              typeof item === "object" ? removeEmptyFields(item) : item
            )
            .filter((item: any) => item !== null && item !== undefined);

          if (filteredArray.length > 0) {
            result[key] = filteredArray;
          }
        } else {
          result[key] = obj[key];
        }
      }
    }

    return result;
  };

  const formik = useFormik({
    initialValues,
    validationSchema: createPopupSchema,
    onSubmit: async (values) => {
      const params = { ...values } as any;
      if (
        (params.domain && params.domain === "https://") ||
        (params.domain && params.domain === "http://")
      ) {
        params.domain = null;
      }
      const [error, result] = await catchAsync(
        cmsHubMembershipApi.companiesCreate(removeEmptyFields(params)),
        setLoading
      );
      if (error) {
        typeof error === "string"
          ? toast.error(error)
          : formik.setErrors(error);
        return;
      }
      toast.success(result.message);
      refesh();
      return;
    },
  });

  useEffect(() => {
    if (!open) {
      formik.resetForm();
    }
  }, [open]);

  return (
    <Modal
      isOpen={open}
      centered={true}
      scrollable={false}
      style={{ width: "60%", maxWidth: "70%", minWidth: "850px" }}
    >
      <div className="d-flex justify-content-between pt-3 pb-2 px-3 items-center">
        <div style={{ fontSize: "18px", fontWeight: "700" }}>
          {t("media.MediaRegistrationPresscon")}
        </div>
        <div onClick={() => onClose()}>
          <i
            className="ri-close-line"
            style={{ fontSize: "20px", cursor: "pointer" }}
          ></i>
        </div>
      </div>
      <ModalBody>
        <form onSubmit={formik.handleSubmit} style={{ width: "100%" }}>
          <div className="d-flex flex-column gap-3">
            <div className="row">
              <div className="col-6 d-flex flex-column gap-3">
                <div className="form-group row">
                  <label className="col-sm-4 col-form-label">
                    {t("media.MediaName")}
                    <span className="text-danger"> *</span>
                  </label>
                  <div className="col-sm-8">
                    <Input
                      type="text"
                      className="form-control"
                      {...formik.getFieldProps("company_name")}
                      placeholder={t("media.PleaseEnterTheNameOfEachItem")}
                      maxLength={50}
                      invalid={!!(formik.values.company_name && formik.errors.company_name)}
                    />
                    <FormFeedback>{formik.values.company_name ? formik.errors.company_name : ""}</FormFeedback>
                  </div>
                </div>
                <div className="form-group row">
                  <label className="col-sm-4 col-form-label">
                    {t("media.DomainURL")}
                  </label>
                  <div className="col-sm-8">
                    <Input
                      type="text"
                      className="form-control"
                      {...formik.getFieldProps("domain")}
                      maxLength={200}
                      invalid={!!formik.errors.domain}
                    />
                    <FormFeedback>{formik.errors.domain}</FormFeedback>
                  </div>
                </div>
                <div className="form-group row">
                  <label className="col-sm-4 col-form-label">
                    {t("media.DomainStartDate")}
                  </label>
                  <div className="col-sm-8">
                    <DateTimePickerCustom
                      isNotFilterTime={true}
                      isNotshowTimeSelect={true}
                      className="w-100"
                      wrapperClassName="w-100"
                      placeholder="----:--:--"
                      dateFormat="yyyy-MM-dd"
                      date={formik.values?.domain_start_date}
                      onChange={(date) => {
                        formik.setFieldValue("domain_start_date", date);
                        if (
                          moment(date) >
                          moment(formik.values?.domain_end_date)
                        ) {
                          formik.setFieldValue("domain_end_date", null);
                          return;
                        }
                      }}
                    />
                  </div>
                </div>
              </div>
              <div className="col-6 d-flex flex-column gap-3">
                <div style={{ height: "39px" }}></div>
                <div style={{ height: "39px" }}></div>
                <div className="form-group row">
                  <label className="col-sm-4 col-form-label whitespace-nowrap">
                    {t("media.DomainExpirationDate")}
                  </label>
                  <div className="col-sm-8">
                    <DateTimePickerCustom
                      isNotFilterTime={true}
                      isNotshowTimeSelect={true}
                      className="w-100"
                      wrapperClassName="w-100"
                      dateFormat="yyyy-MM-dd"
                      placeholder="----:--:--"
                      date={formik.values?.domain_end_date}
                      onChange={(date) => {
                        formik.setFieldValue("domain_end_date", date)
                      }
                      }
                      minDate={formik.values?.domain_start_date}
                    />
                  </div>
                </div>
              </div>
            </div>
            <div>{t("media.FooterSettings")}</div>
            <div className="row">
              <div className="col-6 d-flex flex-column gap-3">
                <div className="form-group row">
                  <label className="col-sm-4 col-form-label">
                    {t("media.RegistrationNumber")}
                  </label>
                  <div className="col-sm-8">
                    <Input
                      type="text"
                      className="form-control"
                      {...formik.getFieldProps(
                        "footer_configs.registration_number"
                      )}
                      placeholder={t("media.PleaseEnterYourInformation")}
                      maxLength={30}
                    />
                  </div>
                </div>
                <div className="form-group row">
                  <label className="col-sm-4 col-form-label whitespace-nowrap">
                    {t("media.BusinessRegistrationNumber")}
                  </label>
                  <div className="col-sm-8">
                    <Input
                      type="text"
                      className="form-control"
                      {...formik.getFieldProps(
                        "footer_configs.company_registration_number"
                      )}
                      placeholder={t("media.PleaseEnterYourInformation")}
                      maxLength={30}
                    />
                  </div>
                </div>
                <div className="form-group row">
                  <label className="col-sm-4 col-form-label">
                    {t("media.Editor")}
                  </label>
                  <div className="col-sm-8">
                    <Input
                      type="text"
                      className="form-control"
                      {...formik.getFieldProps("footer_configs.chief_editor")}
                      placeholder={t("media.PleaseEnterYourInformation")}
                      maxLength={30}
                    />
                  </div>
                </div>
                <div className="form-group row">
                  <label className="col-sm-4 col-form-label">
                    {t("media.MainPhoneNumber")}
                  </label>
                  <div className="col-sm-8">
                    <Input
                      type="text"
                      className="form-control"
                      {...formik.getFieldProps("footer_configs.main_phone")}
                      placeholder={t("media.PleaseEnterYourInformation")}
                      maxLength={30}
                    />
                  </div>
                </div>
              </div>
              <div className="col-6 d-flex flex-column gap-3">
                <div className="form-group row">
                  <label className="col-sm-4 col-form-label">
                    {t("media.Publisher")}
                  </label>
                  <div className="col-sm-8">
                    <Input
                      type="text"
                      className="form-control"
                      {...formik.getFieldProps("footer_configs.publisher")}
                      placeholder={t("media.PleaseEnterYourInformation")}
                      maxLength={30}
                    />
                  </div>
                </div>
                <div className="form-group row">
                  <label className="col-sm-4 col-form-label whitespace-nowrap">
                    {t("media.YouthProtectionOfficer")}
                  </label>
                  <div className="col-sm-8">
                    <Input
                      type="text"
                      className="form-control"
                      {...formik.getFieldProps(
                        "footer_configs.youth_protection_officer"
                      )}
                      maxLength={30}
                      placeholder={t("media.PleaseEnterYourInformation")}
                    />
                  </div>
                </div>
                <div className="form-group row">
                  <label className="col-sm-4 col-form-label">
                    {t("media.Address")}
                  </label>
                  <div className="col-sm-8">
                    <Input
                      type="text"
                      className="form-control"
                      maxLength={30}
                      {...formik.getFieldProps("footer_configs.address")}
                      placeholder={t("media.PleaseEnterYourInformation")}
                    />
                  </div>
                </div>
              </div>
            </div>
            <div className="hstack gap-2 mt-3 justify-content-center">
              <button
                className="btn btn-soft-secondary fs-14"
                type="button"
                color="light"
                onClick={() => onClose()}
                style={{ width: "150px" }}
              >
                {t("cms_media.cancellation")}
              </button>
              <button
                type="submit"
                className="btn btn-primary fs-14"
                style={{ width: "150px" }}
                disabled={!formik.isValid || !formik.dirty}
              >
                {loading ? <Spinner size="sm me-2"></Spinner> : null}
                {t("cms_media.save")}
              </button>
            </div>
          </div>
        </form>
      </ModalBody>
    </Modal>
  );
};
export default ModalRegisterPresscon;
