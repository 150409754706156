import { AxiosResponse } from "axios";
import { APIClient } from "./axiosClient";
import {
  IAdsAccount,
  IAdsAccountHistory,
  IAgency,
  IMedia,
  INotification,
  IRevenue,
  IType,
} from "./types/_media";
import { PaginationResponse, ResponseData } from "./types/_public";

import { IresMediaCmsHub } from "./types/_cmsHub";
import { QueryParams } from "components/Hooks/useQueryParams";
const api = new APIClient();

const path = "/companies";

const MediaApi = {
  getMedia(
    dataForm: QueryParams
  ): Promise<AxiosResponse<IresMediaCmsHub, any>> {
    const url = `${path}`;
    return api.get(url, dataForm);
  },
  getSummaries(): Promise<AxiosResponse<IresMediaCmsHub, any>> {
    const url = `${path}/summaries`;
    return api.get(url);
  },
  updateNote({
    id,
    note,
  }: {
    id: number;
    note: string;
  }): Promise<AxiosResponse<IresMediaCmsHub, any>> {
    const url = `${path}/${id}/note`;
    return api.put(url, { note });
  },
  updateCompanies({
    id,
    data,
  }: {
    id: number;
    data: any;
  }): Promise<AxiosResponse<IresMediaCmsHub, any>> {
    const url = `${path}/${id}`;
    return api.put(url, data);
  },
  getRevenues(
    dataForm: QueryParams
  ): Promise<AxiosResponse<IresMediaCmsHub, any>> {
    const url = `${path}/revenues/list`;
    return api.get(url, dataForm);
  },
  getusers(id: number): Promise<AxiosResponse<IresMediaCmsHub, any>> {
    const url = `${path}/${id}/users`;
    return api.get(url);
  },
  updateRole(
    id: number,
    payload: any
  ): Promise<AxiosResponse<IresMediaCmsHub, any>> {
    const url = `${path}/${id}/users/roles`;
    return api.post(url, payload);
  },
  getRevenuesSummaries(): Promise<AxiosResponse<IresMediaCmsHub, any>> {
    const url = `${path}/revenues/summaries`;
    return api.get(url);
  },
  medias(
    params: any
  ): Promise<AxiosResponse<ResponseData<IMedia[]> & PaginationResponse, any>> {
    const url = `${path}`;
    return api.get(url, params);
  },
  notifications(
    params: any
  ): Promise<
    AxiosResponse<ResponseData<INotification[]> & PaginationResponse, any>
  > {
    const url = `/partner-notices`;
    return api.get(url, params);
  },
  revenue(
    params: any
  ): Promise<
    AxiosResponse<ResponseData<IRevenue[]> & PaginationResponse, any>
  > {
    const url = `/partner-ads`;
    return api.get(url, params);
  },
  adsAccount(
    params: any
  ): Promise<
    AxiosResponse<ResponseData<IAdsAccount[]> & PaginationResponse, any>
  > {
    const url = `/partner-ads-account`;
    return api.get(url, params);
  },
  adsAccountRevenueRate(
    params: any
  ): Promise<
    AxiosResponse<ResponseData<IAdsAccount[]> & PaginationResponse, any>
  > {
    const url = `/partner-ads/revenue-rate`;
    return api.get(url, params);
  },
  adsAccountHistory(
    params: any
  ): Promise<
    AxiosResponse<ResponseData<IAdsAccountHistory[]> & PaginationResponse, any>
  > {
    const url = `/partner-ads/revenue-rate-history`;
    return api.get(url, params);
  },
  allMedias(
    params: any = {}
  ): Promise<AxiosResponse<ResponseData<IMedia[]>, any>> {
    const url = `masters/partners`;
    return api.get(url, params);
  },
  postMedia(dataForm: any): Promise<AxiosResponse<ResponseData<IMedia>, any>> {
    const url = `${path}`;
    return api.post(url, dataForm);
  },
  putMedia(
    id: string | number,
    dataForm: any
  ): Promise<AxiosResponse<ResponseData<IMedia>, any>> {
    const url = `${path}/${id}`;
    return api.put(url, dataForm);
  },
  postNotificationMedia(
    dataForm: any
  ): Promise<AxiosResponse<ResponseData<INotification>, any>> {
    const url = `/partner-notices`;
    return api.post(url, dataForm);
  },
  putNotificationMedia(
    id: string | number,
    dataForm: any
  ): Promise<AxiosResponse<ResponseData<INotification>, any>> {
    const url = `/partner-notices/${id}`;
    return api.put(url, dataForm);
  },
  deleteNotificationMedia(
    id: string | number,
    dataForm: any
  ): Promise<AxiosResponse<ResponseData<IMedia>, any>> {
    const url = `/partner-notices/${id}`;
    return api.delete(url, dataForm);
  },
  putAccountMedia(
    id: string | number,
    dataForm: any
  ): Promise<AxiosResponse<ResponseData<IMedia>, any>> {
    const url = `${path}/${id}/create-account`;
    return api.put(url, dataForm);
  },
  deleteMedia(
    id: string | number,
    dataForm: any
  ): Promise<AxiosResponse<ResponseData<IMedia>, any>> {
    const url = `${path}/${id}`;
    return api.delete(url, dataForm);
  },
  allTypes(
    params: any = {}
  ): Promise<AxiosResponse<ResponseData<IType[]>, any>> {
    const url = `masters/domains`;
    return api.get(url, params);
  },
  refreshKey(
    id: string | number,
    params: any = {}
  ): Promise<AxiosResponse<ResponseData<IMedia>, any>> {
    const url = `${path}/${id}/refresh`;
    return api.get(url, params);
  },
  allAdsAgency(
    params: any = {}
  ): Promise<AxiosResponse<ResponseData<IAgency[]>, any>> {
    const url = `masters/ads-agency`;
    return api.get(url, params);
  },
  postPartnerAdsAccountRevenueRate(
    dataForm: any
  ): Promise<AxiosResponse<ResponseData<INotification>, any>> {
    const url = `/partner-ads/revenue-rate`;
    return api.post(url, dataForm);
  },
  putPartnerAdsAccountRevenueRate(
    id: string | number,
    dataForm: any
  ): Promise<AxiosResponse<ResponseData<INotification>, any>> {
    const url = `/partner-ads/revenue-rate/${id}`;
    return api.put(url, dataForm);
  },
  adsAccountNormal(
    params: any
  ): Promise<
    AxiosResponse<ResponseData<IAdsAccount[]> & PaginationResponse, any>
  > {
    const url = `/partner-ads-account`;
    return api.get(url, params);
  },
  deleteAdsAccountRevenueRate(
    id: string | number,
    dataForm: any
  ): Promise<AxiosResponse<ResponseData<IMedia>, any>> {
    const url = `/partner-ads/revenue-rate/${id}`;
    return api.delete(url, dataForm);
  },

  getConfig(id: any): Promise<AxiosResponse<ResponseData<IType[]>, any>> {
    const url = `/partners/${id}/configs`;
    return api.get(url);
  },

  updateConfig(
    id: string | number,
    dataForm: any
  ): Promise<AxiosResponse<ResponseData<any>, any>> {
    const url = `/partners/${id}/configs`;
    return api.put(url, dataForm);
  },
  getDetailMedia(
    id:number,
  ) {
    return api.get(`/companies/${id}`);
  },
  getMediaByID(
    id: string
  ): Promise<AxiosResponse<any, any>> {
    const url = `${path}/${id}`;
    return api.get(url, id);
  },
  getGeneralMembers(
    id: number,
    params:any
  ): Promise<AxiosResponse<any, any>> {
    return api.get(`/companies/${id}/general-members`,params);
  },
  sendPasswordByEmail(
    id: number,
  ): Promise<AxiosResponse<any, any>> {
    return api.post(`/companies/${id}/general-members/send-password`,{});
  },
  sendEmailMember(
    id: number,
  ): Promise<AxiosResponse<any, any>> {
    return api.post(`/companies/${id}/users/send-password`,{});
  },
  companiesWithdrawal(
    dataForm: QueryParams
  ): Promise<AxiosResponse<any, any>> {
    return api.get(`/companies/withdrawal/list`,dataForm);
  },
  companiesWithdrawalComplete(
    id: number
  ): Promise<AxiosResponse<any, any>> {
    return api.post(`/companies/withdrawal/complete/${id}`,{});
  },
};
export default MediaApi;
